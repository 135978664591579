/* SCSS Document */
/*** IMPORTS ***/
@import 'bootstrap'; /* BOOTSTRAP */
@import 'mixins'; /* GLOBAL VARIABLES */
@import url('https://fonts.googleapis.com/css?family=Cantata+One|Imprima|Ovo');

/*** BASICS ***/
/* 3 RESPONSIVE COLUMNS */
.wrapper-big {
  display: block;
  margin: inherit auto;
}
@media (min-width: 1200px) {
  .wrapper-big {
    width: 960px;
  }
}
@media (min-width: 768px) and (max-width: 1200px) {
  .wrapper-big {
    width: 80%;
  }  
}
@media (max-width: 768px) {
  .wrapper-big {
    width: 90%;
  }
}

/* SIMPLE COMMANDS */
.right {
	text-align: right;
}
.left {
	text-align: left;
}
.center {
	text-align: center;
}


/*** FRAMEWORK ***/
html {
  height: 100%;
  body {
    height: 100%;
    font-size: $font-size-base;
    margin: 0px;
    
    h1, h2, h3, header, footer {
      font-family: $text-relevant;
    }
    article, form, p, section, label, button, input, textarea {
      font-family: $text-content;
    }
    
    header {
      background-color: $bg-medium;
      color: $font-medium;
      position: fixed;
      vertical-align: middle;
      width: 100%;
      z-index: 2;
      nav, .nav, .navbar {
        font-family: $text-relevant;
        margin: 10px auto;
        &::after, &::before {
          content: none;
        }
        .open > a:focus, li > a:focus {
          background-color: inherit;
          border: none;
        }
        a {
          color: $font-medium;
          display: block;
          font-family: $text-relevant;
          font-size: 1em;
          padding: 0;
          text-decoration: none;
          &:hover {
            background-color: transparent !important;
            border-bottom: 2px solid $font-medium;
            text-decoration: none;
          }
          &#logo {
            display: inline-block;
            padding: 0;
            &:hover {
              border-bottom: none;
            }
            img {
              max-height: 60px;
              width: auto;
            }
          }
        }
        ul {
          list-style-type: none;
          margin: 0 auto;
          padding: 0;
          li {
            display: inline-block;
            margin: 0;
            padding: 0;
            vertical-align: top;
          }
        }
        .navbar-toggle {
          margin-right: 0;
        }
        .icon-bar {
          background-color: $purple;
        }
      }
      .navbar-sm {
        a:hover {
          border: none;
        }
        li {
          display: inline-block;
          width: auto;
        }
        img {
          height: 24px;
          width: auto;
        }
      }
    }
    
    footer {
      background-color: $bg-dark;
      color: $font-light;
      padding: 20px 0;
      .nav-item {
        font-size: .7rem;
      }
      a {
        color: $font-lighter;
        &:hover {
          color: rgba(255, 255, 255, .8);
          text-decoration: none;
        }
      }
    }
    
    section#main {
      background-color: $bg-light;
      min-height: calc(100vh - 40px);
      overflow: auto;
      position: relative;
      padding-top: 100px;
      a {
        text-decoration: none;
        &:not(.btn) {
          color: $font-medium;
          &:hover {
            color: $font-light;
          }
        }
        &:not(.btn, .noline) {
          border-bottom: 1px dotted $font-medium;
          &:hover {
            border-bottom: 1px solid $font-light;
          }
        }
      }
      form {
        label {
          display: block;
          font-size: 18px;
          font-family: $text-special;
          font-weight: normal;
        }
        input, textarea { 
          font-family: $text-content;
          margin-bottom: 20px;
          padding: 5px 9px;
          width: 100%;
        }
        input[type=submit] {
          background-color: #9F009A;
          border: none;
          color: white;
          font-family: $text-special;
          font-size: 18px;
          padding: 5px 50px;
          text-transform: uppercase;
          width: auto;
        }
        textarea {
          height: 90px;
        }
      }
      
      .reel {
        .slide {
          height: auto;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: auto;
          min-width: 100%;
          &:not(.in, .out, .ready) {
            left: 50%;
            transform: translate(-50%, -50%);
          }
          &:not(:first-child) {
            display: none;
          }
          &.out {
            display: none !important;
            left: 100%;
          }
          &.in {
            display: block;
          }
          &.ready {
            display: block;
            top: 50% !important;
            &._in {
              left: 100%;
            }
          }
        }
      }
    }
    
    #livebox-bg {
      background-color: rgba(0,0,0,.85);
      display: none;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      z-index: 3;
      #livebox-container {
        left: 50%;
        max-height: 80vh;
        max-width: 90vw;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        &:not(.gallery) {
          background-color: white;
          border-radius: 8px;
          #livebox-item {
            overflow: auto;
          }
        }
        #livebox-close {
          cursor: pointer;
          position: absolute;
          top: 10px;
          right: 10px;
        }
        #livebox-item {
          display: block;
          max-height: calc(80vh - 80px);
          margin: 40px;
          position: relative;
          max-width: calc(100% - 80px);
          img {
            height: auto;
            max-height: calc(80vh - 80px);
            max-width: calc(90vw - 100px);
            width: auto;
          }
        }
      }
    }
    
    /* WIDESCREEN */
    @media (min-width: 1200px) {
      header {
        .wrapper-big {
          width: 90%;
        }
      }
    }
    
    /* TABLET AND WIDESCREEN */
    @media (min-width: 769px) and (max-width: 1200px) {
      header {
        .wrapper-big {
          width: 100%;
        }
      }
    }
    @media (min-width: 769px) {
      header {
        height: 100px;
        nav {
          margin: 20px auto;
          #navbar-main {
            display: inline-block;
            float: right;
            .navbar-sm {
              margin-top: 18px;
            }
            .navbar-nav {
              margin-top: 10px;
              li {
                margin-right: 10px;
                padding-right: 20px;
                &:last-child {
                  padding-right:  0;
                }
                a {
                  padding: 0;
                }
              }
            }
          }
        }
        .navbar {
          display: flex;
          justify-content: space-between;
        }
      }
      footer {
        .ft2 {
          .nav-item {
            text-align: right;
          }
        }
        .vb {
          text-align: center;
        }
      }
      .mobile {
        display: none !important;
      }
    }
    /* TABLET AND MOBILE */
    @media (max-width: 991px) {
      header {
        .navbar {
          a#logo {
            img {
              max-height: 50px;
              width: auto;
            }
          }
          
        }
        .wrapper-big {
          width: 100%;
        }
      }
      
      #livebox-bg {
        #livebox-container {
          max-height: 92vh;
          max-width: 96vw;
          #livebox-close {
            top: 4px;
            right: 4px;
          }
          #livebox-item {
            max-height: calc(92vh - 30px);
            max-width: calc(100% - 30px);
            img {
              max-height: calc(92vh - 30px);
              max-width: calc(96vw - 30px);
            }
          }
        }
      }
      
    }
    /* MOBILE */
		@media (max-width: 767px) {
      .wide {
        display: none !important;
        height: 0px;
        width: 0px;
      }
      header {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 1;
        nav, .nav {      
          a#logo {
            max-width: calc(100% - 44px);
            img {
              max-width: 100%;
            }
          }
          div#navbar-main {
            > ul {
             > li {
                display: block;
                padding: 9px 0;
                text-align: center;
                width: 100%;
              }
            }
            .navbar-sm {
              display: inline-block;
              width: auto;
            }
          }
          
        }
        .navbar-header {
          display: flex;
          justify-content: space-between;
          width: 100%;
          &::before, &::after {
            content: none;
          }
        }
      }
      section#main {
        padding-top: 72px;
      }
    }
  }
}